import React from "react"

//Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/navigation/header"
import Footer from "../components/footer"
import Title from "../components/title"

// Styles
import * as styles from "../styles/components/faq.module.scss"
import { Container, Accordion, Card } from "react-bootstrap"

const frequentlyAskedQuestions = [
  {
    question: "What do I need to know about Inverters?",
    answer:
      "Most inverters used in off-grid homes connect directly to the AC load center, and therefore energize the whole house. Inverter output is either modified sine wave (cheaper) or sine wave (cleaner). Other features to compare are charger size, efficiency and warranty.",
  },
  {
    question: "What do I need to know about Batteries?",
    answer:
      "Batteries are your gas tanks. Lithium Ion batteries are recommended as they can handle deeper discharges better.  Proper charging and limiting discharging is the key to long battery life.",
  },
  {
    question: "How big a solar system do I need?",
    answer:
      "The Photovoltaic (PV) and battery system are sized to meet your power needs. A qualified installer will review your electrical loads and help you select the right equipment. For a full time home, a 1kW system will deliver on average about 4.5 kWh in the summer. Note that you can increase your solar system size at any time as your needs grow. However, it is wise to select a larger battery initially as they must be replaced rather than expanded due to the general rule about not mixing old and new batteries.",
  },
  {
    question: "How can I choose a solar power system for use?",
    answer:
      "Your total electrical demand (or 'load') can be calculated by your installer (Sunterra). You will need to provide them with information such as the number of lights, appliances etc. Then according to your requirement, we can calculate a suitable one for you.",
  },
  {
    question: "How can I install a solar power system for my requirement",
    answer:
      "Sunterra solar will you give you a step by step installation process to give you clarity on our process and procedure",
  },
  {
    question: "What can affect the amount of solar energy received?",
    answer:
      "The strength of the solar energy available depends on the time of year, the time of day, and the latitude of the generation point. The amount of energy generated can be further affected by the amount of dust and water vapor in the air, and the amount of cloud cover.",
  },
]

const Faq = () => {
  return (
    <>
      <Header />
      <SEO
        title="FAQ"
        article={false}
        pathname="/faq"
        description="Talk to us about how you can earn and save money every month with solar!"
      />
      <Layout>
        <Title text="FAQ's" subtitle="Frequently Asked Questions" />

        <Container className="w-75">
          {frequentlyAskedQuestions.map((question, idx) => (
            <>
              <Accordion defaultActiveKey="0" className="mb-5">
                <Card className={`border-0 bg-white ${styles.accordions}`}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="0"
                    className="bg-light"
                  >
                    <h3 className="p-3">
                      <strong>{question.question}</strong>
                    </h3>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0" className="pb-3">
                    <Card.Body>
                      <p className="p-3 lh-lg">{question.answer}</p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </>
          ))}
        </Container>
      </Layout>
      <Footer />
    </>
  )
}

export default Faq
